import React from "react";
import Layout from '../components/layout'
import Hero from "../components/hero";
import HeroImage from "../images/seattlepic.jpg";
import "./wedding-details.scss";

export default () => {
  return <Layout hero={<Hero img={HeroImage} className="desktop-only" />}>
      <Hero className="mobile-only" img={HeroImage} />
      <div className="flex flex-col items-center lg:flex-row lg:items-stretch">
        <div className="details-date desktop-only">
          <div>Friday</div>
          <div>October 25</div>
          <div>2019</div>
        </div>
        <div className="details-date mobile-only flex justify-center">
          Friday, Oct. 25, 2019
        </div>
        <div className="details-location w-full lg:w-auto lg:flex-grow welcome">
          <div className="background-filter bg-op-green items-center">
            <div className="details-location-event  md:w-1/2">
              <div className="event-name">welcome reception</div>
              <div className="event-venue">The Tigermen Den</div>
              <div className="event-address">
                <p className="address-line">3113 royal st</p>
                <p className="address-line">new orleans, la 70117</p>
                <p className="address-line">7:30 - 10pm</p>
                <p className="address-line italic">casual attire</p>
                <p className="address-line italic">costumes encouraged</p>
              </div>
            </div>
            <div className="map justify-center">
              <a href="https://goo.gl/maps/FMf2xbjLZot" target="_blank">
                <img src="https://maps.googleapis.com/maps/api/staticmap?center=The+Tigermen+Den+3113+Royal+St,+New+Orleans,+LA+70117&size=300x225&markers=color:blue|The+Tigermen+Den+3113+Royal+St,+New+Orleans,+LA+70117&zoom=15&key=AIzaSyDSjdhGi-0effbnLYz1etjplAQHva7DVI4" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="details-section">
        <div className="details-date desktop-only">
          <div>Saturday</div>
          <div>October 26</div>
          <div>2019</div>
        </div>
        <div className="details-date mobile-only">
          Saturday, Oct. 26, 2019
        </div>
        <div className="details-location flex-grow saturday">
          <div className="background-filter bg-op-blue flex flex-col">
            <div className="flex items-center justify-around flex-col md:flex-row">
              <div className="details-location-event w-full md:w-1/2">
                <div className="event-name">ceremony</div>
                <div className="event-venue">New Orleans</div>
                <div className="event-venue">Pharmacy Museum</div>
                <div className="event-address">
                  <p className="address-line">514 chartres st</p>
                  <p className="address-line">new orleans, la 70130</p>
                  <p className="address-line">5pm</p>
                  <p className="address-line italic">cocktail attire</p>
                </div>
              </div>
              <div className="map">
                <a href="https://goo.gl/maps/6EBQgW6tmVR2" target="_blank">
                  <img src="https://maps.googleapis.com/maps/api/staticmap?center=new+orleans+pharmacy+museum+514+chartres+St,+New+Orleans,+LA+70130&size=300x225&markers=color:blue|new+orleans+pharmacy+museum+514+chartres+St,+New+Orleans,+LA+70130&zoom=15&key=AIzaSyDSjdhGi-0effbnLYz1etjplAQHva7DVI4" />
                </a>
              </div>
            </div>
            <div className="flex items-center justify-around flex-col md:flex-row">
              <div className="w-full md:w-1/2">
                <div className="details-location-event">
                  <div className="event-name">second line parade</div>
                  <div className="event-address">
                    <p className="address-line">
                      Immediately following the ceremony
                    </p>
                    <p className="address-line italic">
                      comfortable shoes recommended
                    </p>
                  </div>
                </div>

                <div className="details-location-event">
                  <div className="event-name">reception</div>
                  <div className="event-venue">Napoleon House</div>
                  <div className="event-address">
                    <p className="address-line">500 chartres st</p>
                    <p className="address-line">new orleans, la 70130</p>
                    <p className="address-line">6 - 11pm</p>
                  </div>
                </div>
                <div className="details-location-event">
                  <div className="event-name">after party</div>
                  <div className="event-venue">Frenchmen Street</div>
                  <div className="event-address">
                    <p className="address-line">11pm - ?</p>
                  </div>
                </div>
              </div>
              <div className="map flex flex-col justify-center">
                <a href="https://goo.gl/maps/TPtaYVUoSBt" target="_blank">
                  <img src="https://maps.googleapis.com/maps/api/staticmap?center=Napoleon+House+500+chartres+St,+New+Orleans,+LA+70130&size=300x225&markers=color:blue|Napoleon+House+500+chartres+St,+New+Orleans,+LA+70130&zoom=15&key=AIzaSyDSjdhGi-0effbnLYz1etjplAQHva7DVI4" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
}
